
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code2 } from "@/views/resources/documentation/element-ui/basic/layout/code";

export default defineComponent({
  name: "column-spacing",
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code2
    };
  }
});
